import "./Projects.css";
import cj from "./cj.jpg";
import benny from "./benny.jpeg";
import low from "./low.jpg";
import behold from "./behold.jpeg";
import telia from "./telia.jpg";
import forcedhand from "./forced-hand.jpg";
import vampires4hire from "./vampires4hire.jpg";
import atlas from "./atlas-at-hold.jpg";
import ghosts from "./always-chasing-ghosts.jpg";

function Projects() {

    return (
        <>
        <ul className="project__list">
            <li className="project__item fade-in-intersection">
                <div className="project__inner">
                    <div className="project__meta">
                        <span className="project__title">Behold</span>
                        <span className="project__artist">See Through You</span>
                        <span className="project__genre">Metal</span>
                        <span className="project__credits">mix and master</span>
                        <a href="https://open.spotify.com/track/7yxANPBFaAx7b55vMRPVMD?si=d79d088c50cb4793" className="project__link" target='_blank' rel='noreferrer'>Listen on Spotify</a>
                    </div>
                    <div className="project__body">
                        <img src={behold} alt=''/>
                    </div>
                </div>
            </li>
            <li className="project__item fade-in-intersection">
                <div className="project__inner">
                    <div className="project__meta">
                        <span className="project__title">I Am Not Okay</span>
                        <span className="project__artist">Benny Bugz</span>
                        <span className="project__genre">Rap</span>
                        <span className="project__credits">mix, master and produce</span>
                        <a href="https://open.spotify.com/track/706HhC23xP0sW6JmgmTgCi?si=120134ed33724938" className="project__link" target='_blank' rel='noreferrer'>Listen on Spotify</a>
                    </div>
                    <div className="project__body">
                        <img src={benny} alt=''/>
                    </div>
                </div>
            </li>
            <li className="project__item fade-in-intersection">
                <div className="project__inner">
                    <div className="project__meta">
                        <span className="project__title">Down to My Soul</span>
                        <span className="project__artist">CJ Wood</span>
                        <span className="project__genre">Rock</span>
                        <span className="project__credits">mix, master and produce</span>
                        <a href="https://open.spotify.com/track/7MKVtSPBqPoJNxN8931u1T?si=c06f5cddda414f1b" className="project__link" target='_blank' rel='noreferrer'>Listen on Spotify</a>
                    </div>
                    <div className="project__body">
                        <img src={cj} alt=''/>
                    </div>
                </div>
            </li>
            <li className="project__item fade-in-intersection">
                <div className="project__inner">
                    <div className="project__meta">
                        <span className="project__title">Low</span>
                        <span className="project__artist">Mikahl Lawless</span>
                        <span className="project__genre">Rap</span>
                        <span className="project__credits">mix, master and produce</span>
                        <a href="https://open.spotify.com/track/3pHeEFYWBSyK2IlkTQDXOg?si=bca013b50e5c4788" className="project__link" target='_blank' rel='noreferrer'>Listen on Spotify</a>
                    </div>
                    <div className="project__body">
                        <img src={low} alt=''/>
                    </div>
                </div>
            </li>
            <li className="project__item fade-in-intersection">
                <div className="project__inner">
                    <div className="project__meta">
                        <span className="project__title">I Lost My Knife Again </span>
                        <span className="project__artist">Vampires4hire</span>
                        <span className="project__genre">Rap</span>
                        <span className="project__credits">recording, mixing and mastering</span>
                        <a href="https://open.spotify.com/track/4Io9uGuf63rMP9qTSFOlkr?si=65a87c96df674074" className="project__link" target='_blank' rel='noreferrer'>Listen on Spotify</a>
                    </div>
                    <div className="project__body">
                        <img src={vampires4hire} alt=''/>
                    </div>
                </div>
            </li>
            <li className="project__item fade-in-intersection">
                <div className="project__inner">
                    <div className="project__meta">
                        <span className="project__title">Tall Grass</span>
                        <span className="project__artist">Forced Hand</span>
                        <span className="project__genre">Metal</span>
                        <span className="project__credits">recording, mixing and mastering</span>
                        <a href="https://open.spotify.com/track/4IAVMuViCddIvUIdBM1PR2?si=3f5261310a9249df" className="project__link" target='_blank' rel='noreferrer'>Listen on Spotify</a>
                    </div>
                    <div className="project__body">
                        <img src={forcedhand} alt=''/>
                    </div>
                </div>
            </li>
            <li className="project__item fade-in-intersection">
                <div className="project__inner">
                    <div className="project__meta">
                        <span className="project__title">Buried</span>
                        <span className="project__artist">Telia</span>
                        <span className="project__genre">Pop</span>
                        <span className="project__credits">Mastering</span>
                        <a href="https://open.spotify.com/track/1KN0JJLgcxqVT3fi4bgmFw?si=a606d31d085245c5" className="project__link" target='_blank' rel='noreferrer'>Listen on Spotify</a>
                    </div>
                    <div className="project__body">
                        <img src={telia} alt=''/>
                    </div>
                </div>
            </li>
            <li className="project__item fade-in-intersection">
                <div className="project__inner">
                    <div className="project__meta">
                        <span className="project__title">Deadweight</span>
                        <span className="project__artist">Atlas At Hold</span>
                        <span className="project__genre">Metal</span>
                        <span className="project__credits">recording, mixing and mastering</span>
                        <a href="https://open.spotify.com/track/0Rv4k4bZgON0F0WKv3Ajj1?si=7854d92b5bef4bf2" className="project__link" target='_blank' rel='noreferrer'>Listen on Spotify</a>
                    </div>
                    <div className="project__body">
                        <img src={atlas} alt=''/>
                    </div>
                </div>
            </li>
            <li className="project__item fade-in-intersection">
                <div className="project__inner">
                    <div className="project__meta">
                        <span className="project__title">Whispers of Worth</span>
                        <span className="project__artist">Always Chasing Ghosts</span>
                        <span className="project__genre">Metal</span>
                        <span className="project__credits">recording, mixing and mastering</span>
                        <a href="https://open.spotify.com/track/5lArtNRXOihgjDZFycCoEG?si=340b2050e9ff42f9" className="project__link" target='_blank' rel='noreferrer'>Listen on Spotify</a>
                    </div>
                    <div className="project__body">
                        <img src={ghosts} alt=''/>
                    </div>
                </div>
            </li>
        </ul>
        </>
    )
}

export default Projects;