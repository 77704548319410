import React from 'react';
import ExpandableList from './ExpandableList';
import './Gear.css';
import './App.css';
import Header from './Header';
import Footer from './Footer';

function Gear() {
  const items = [
    {
      header: 'Computers',
      content: ['Macbook Pro - Custom Dual HDD Producers Edition', 'Imac I5 3.2 Ghz Quad Core 32 GB Ram', 'Mac Mini I7 2GHz 16 GB Ram'],  
      imgPath: require('./gear-computer.jpg')
    },{
      header: 'Monitors',
      content: ['Adam Audio A7X Nearfield', 'Aurotone 5C Super Sound Cubes', 'Fostex 3\' Nearfield Monitors', 'Krk 10\' Subwoofer']
    },{
      header: 'Interfaces',
      content: ['UA Apollo x8p', 'UA Apollo x8', 'UA Apollo x6', 'Audient I4', 'Apogee Duet 2'],
      imgPath: require('./gear-rack.jpg')
    },{
      header: 'Analog Outboard',
      content: ['500 Series:', '2x Neve 542 Tape Machine', 'Neve 1073 Mic Preamp', '2x API 550b Equalizer', 'API 527 Compressor', '2x Focusrite red Mic Preamp', 'Black Lion Audio B12A MKII Mic Preamp',
      'Neve 511 Mic Preamp', 'DBX 560 Compressor', 'JLM Audio LA 500 Optical Compressor', 'Elysia xpressor 500 Stereo Compressor']
    },{
      header: 'Rackmount',
      content: ['JDK Audio R-22 Dual Channel Compressor', '2xKlark Technic EQP-KT','Tech 21 NYC  Sansamp RBI', 'Distressor (2x)']
    },{
      header: 'Guitar/Bass Gear',
      content: ['Marshall JCM 900 (Model 4100)', 'Peavey 6505+', 'Kemper Profiler Powered Rackmount', 'Mesa Boogie Strategy Eight 88', 'Neve RND DI ', '1972 Fender Deluxe Reverb']
    },{
      header: 'Microphones',
      content: ['Stam SA 800 Tube Microphone with upgraded Tube Power supply', 'Custom handmade C12 clone', 'Neumann KM 184 (pair)', 'Mojave MA 301 Fet (Pair)', 'Slate VMS ML1 Microphone system', 'Slate VMS ML 2 (3)', 'Sennheiser E609', 'Shure SM57 (pair)', 'Audix D6', 'Sennheiser 421 (pair)', 'SM58 (3)'],
      imgPath: require('./gear-mics.jpg')
    },{
      header: 'Controller',
      content: ['TC 2290 Dynamic Digital Delay System', 'Softube Console 1 System', 'Presonus Monitor Controller', 'Slate Raven MTI (Pair)', 'AKAI MPK mini']
    },{
      header: 'DAW',
      content: ['Protools', 'Studio One']
    },{
      header: 'Analog Summing',
      content: ['Dangerous Audio Dbox +']
    },{
      header: 'Headphones',
      content: ['Beyerdynamic DT770 Pro','Slate VSX']
    },{
      header: 'Room Correction',
      content: ['Sonarworks Sound ID']
    }
  ];

  return (
    <div className="App Gear">
      <Header/>
      <main>
        <div className='gear-hero'>
          <div className='App-hero__overlay'>
            <hgroup className='fade-in-faster'>
              <h1 className='heading_block'>Our gear</h1>
              <p>Tap a category to expand gear list</p>
            </hgroup>
          </div>
        </div>
        <section className='container_outer'>
          <div className='container_inner fade-in-faster'>
            <ExpandableList items={items} />
          </div>
        </section>
      </main>
      <Footer/>
    </div>
  );
}

export default Gear;