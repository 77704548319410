import React, { useState } from 'react';
import './ExpandableList.css';

const ExpandableList = ({ items }) => {
  const [activeItems, setActiveItems] = useState([]);

  const toggleItem = (index) => {
    if (activeItems.includes(index)) {
      setActiveItems(activeItems.filter((item) => item !== index));
    } else {
      setActiveItems([...activeItems, index]);
    }
  };

  return (
    <div className="expandable-list">
      {items.map((item, index) => (
        <div className="list-item" key={index}>
          <div
            className="list-header"
            onClick={() => toggleItem(index)}
            tabIndex={1}
          >
            {item.header}
          </div>
          <div
            className={`list-content ${
              activeItems.includes(index) ? 'active' : ''
            }`}
          >
            <ul>
              {item.content.map((listItem, i) => (
                <li key={i}>{listItem}</li>
              ))}
            </ul>
            {item.imgPath ? <img className='list-content__img fade-in-faster' src={item.imgPath} alt=''></img> : ''}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExpandableList;
