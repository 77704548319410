import React, { useEffect } from 'react';
import logo from './logo.svg';
import menuBars from './menu-bars.svg';
import './Header.css'; // Import your CSS file
import { Link } from "react-router-dom";

function Header() {
  useEffect(() => {
    const header = document.querySelector('.sticky-header');
    const headerHeight = header.clientHeight;

    function handleScroll() {
      if (window.scrollY > headerHeight) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    }

    window.addEventListener('scroll', handleScroll);

    (function(H){H.className=H.className+="js"})(document.documentElement)
      var nav = document.querySelector("nav ul"),
      navToggle = document.querySelector("nav .skip");
      if (navToggle) {
        navToggle.addEventListener("click",
        function(e) {
        if (nav.className === "open") {
            nav.className = "";
        } else {
            nav.className = "open";
        }
          e.preventDefault();
        }, false);
      }

      /* Any click outside of the nav element will close the menu if it's open */
      var specifiedElement = document.querySelector('nav');
      document.addEventListener('click', function(event) {
      var isClickInside = specifiedElement.contains(event.target);
      if (!isClickInside && nav.className === "open") {
          nav.className = "";
      } 
  });

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className="App-header sticky-header">
        <a className='App-logo-link' href='/'>
          <img src={logo} className="App-logo" alt="logo" />
        </a>
        <nav role="navigation" className="nav">
          <a className="skip" href="#menu">
            <span className="sr-only">menu</span>
            <img src={menuBars} alt="" aria-hidden="true" />
          </a>
          <ul id="menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            {/* <li><a href='/#about'>About</a></li> */}
            <li>
              <Link to='/gear'>Our Gear</Link>
            </li>
            <li>
              <a href='https://calendly.com/bombsheltermusic' target='_blank' rel='noreferrer'>Book Us</a>
            </li>
            {/* <li>
              <Link to="/contact">Contact</Link>
            </li> */}
          </ul>
        </nav>
    </header>
  );
}

export default Header;
