import React from 'react';
import './Contact.css';
import Header from './Header';
import Footer from './Footer';

function Contact() {  
  return (
    <div>
      <Header/>
      <h1>Contact</h1>
      <Footer/>
    </div>
  );
}

export default Contact;