import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './App';
import Gear from './Gear';
import Contact from './Contact';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from './ScrollToTop';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ScrollToTop/>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path='/gear' element={<Gear/>} />
      <Route path='/contact' element={<Contact/>} />
    </Routes>
  </BrowserRouter>
);
