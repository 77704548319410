import './Footer.css'; // Import your CSS file
import facebookIcon from './icon-facebook.svg';
import instagramIcon from './icon-instagram.svg';

function Footer() {

    const currentYear = (new Date().getFullYear());

    return (
        <footer>
            &copy; {currentYear} The Bombshelter. All rights reserved.
            <ul>
                <li><a href='https://calendly.com/bombsheltermusic' target='_blank' rel='noreferrer'>Book a Session</a></li>
                <li><a href='https://account.venmo.com/u/Christopher-Wood-119' target='_blank' rel='noreferrer'>Client Payment Link</a></li>
            </ul>
            <address>
                The Bombshelter<br/>
                (717) 309-9512<br/>
                <a href='mailto:wemixyou2@gmail.com'>wemixyou2@gmail.com</a>
            </address>
            <ul className='social__list'>
                <li>
                    <a href='https://www.facebook.com/bombsheltermusic/' target='_blank' rel='noreferrer'>
                        <img className='social__icon' src={facebookIcon} alt='' aria-hidden="true"/>
                        <span className='sr-only'>The Bombshelter on Facebook</span>
                    </a>
                </li>
                <li>
                    <a href='https://www.instagram.com/bombsheltermusic/' target='_blank' rel='noreferrer'>
                        <img className='social__icon' src={instagramIcon} alt='' aria-hidden="true"/>
                        <span className='sr-only'>The Bombshelter on Instagram</span>
                    </a>
                </li>
            </ul>
        </footer>
    )
}

export default Footer;