import './App.css';
import Header from './Header.js';
import Footer from './Footer';
import Projects from './Projects';
import consolePic from './console.jpg';
import singerPic from './singer.jpg';
import drummerPic from './drummer.jpg';
import cjScottPic from './cj-and-scott.jpg';
import { Link } from 'react-router-dom';

function Home() {
// Function to check if an element is in the viewport
  function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  // Function to handle the fading effect
  function handleFadeIn() {
    const elements = document.querySelectorAll('.fade-in-intersection');
    elements.forEach((element) => {
        if (isInViewport(element)) {
            element.style.opacity = '1';
        }
    });
  }

  // Attach the handleFadeIn function to the scroll event
  window.addEventListener('scroll', handleFadeIn);

  // Initial check in case images are already in the viewport on page load
  handleFadeIn();

  return (
    <div className="App">
      <Header />
      <main>
        <section className='App-hero'>
          <div className='App-hero__overlay'>
            <div className='App-hero__text slide-in-top'>
              <hgroup>
                <h1>Welcome to <span className='heading_block'>The Bombshelter!</span></h1>
              </hgroup>
            </div>
            <div className='App-hero__pic slide-in-bottom'>
              <img src={cjScottPic} alt='C.J. and Scott of Bombshelter'/>
            </div>
          </div>
        </section>
        <section className='container_outer fade-in' id='about'>
          <h2>About The Bombshelter</h2>
          <div className='container_inner'>
            <div className='container_text'>
              <p>The Bombshelter is a recording studio in Red Lion, PA owned by Christopher Wood, who operates the studio alongside Scott Gross. With the formal partnership beginning in 2020 throughout the COVID-19 pandemic, Scott, founding member and songwriter of Long Island's From Autumn to Ashes and Chris of the local outfit Bury the Masses set out to get professional, yet affordable productions to the public. <Link to={"/gear"}>Amassing a collection of both analog and digital gear</Link>, The Bombshelter is tuned to take on any and all of your recording, mixing, mastering, songwriting and production needs. Offering hourly and full day block rates with credits ranging from metal and hardcore to hip-hop and singer/songwriter make us your next choice for your recording needs.</p>
              <p>
                <Link to={"/gear"} className='cta'>Our Gear</Link>
              </p>
            </div>
            <div className='container_pic'>
              <img alt='mixing console' src={consolePic} />
            </div>
          </div>
        </section>
        <section className='container_outer'>
          <h2>Project Spotlight</h2>
          <p>Our production team has meticulously crafted an array of songs that span genres, emotions, and stories waiting to be told.</p>
          <Projects/>
        </section>
        <section className='container_outer'>
          <h2>Listen</h2>
          <div className='container_inner'>
            <div className='container_pic fade-in-intersection'>
              <img alt='drummer with behind kit with studio mics' src={drummerPic} />
            </div>
            <div className='container_text'>
              <p>These songs aren't just audio recordings; they are journeys that will transport you to places you've never been and stir emotions you didn't know you had. Whether you're seeking infectious rhythms that make you move, soul-stirring ballads that tug at your heartstrings, or mind-bending experimental compositions that challenge your musical palate, our produced songs have something for everyone. Step into the world of The Bombshelter's produced music, and let the melodies, lyrics, and arrangements speak to your soul. These songs are not just notes and lyrics; they are moments frozen in time, ready to resonate with you in ways you never imagined.</p>
              <iframe className='playlist-iframe' title='Listen to our work on Spotify' src="https://open.spotify.com/embed/playlist/4Z3S45Na31aPrIW2eVjYzm?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
              {/* <iframe src="https://www.youtube.com/embed/videoseries?si=mToYPPmNSg0X7Yoy&amp;list=OLAK5uy_nBBJ1y_93ELPm5mK011cDlTDwLrgOJKS8" title="YouTube video player" frameBorder="0" width="100%" height="352" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
            </div>
          </div>
        </section>
        <section className='container_outer'>
          <h2>Work With Us!</h2>
          <div className='container_inner'>
            <div className='container_text'>
              <p>Experience, talent and knowledge are the perfect reasons for you to choose The Bombshelter for your next project. Scott Gross has been in the studio for over twenty years and has amassed credits of the likes of From Autumn to Ashes, Lil Peep, Emergency and Vampires4hire. Combined with Chris, they have credits with Mikahl Lawless, See Through You and Benny Bugz, among others. Credits include engineering, mixing, songwriting, production and mastering. They are well versed in multiple genres ranging from metal to hip hop and pop and acoustic. They offer beat-making services for hip hop artists. With the use of cutting edge technology and a long list of analog gear they will find the sound you're looking for and help create the framework and ideas for your creativity. </p>
              <p>
                <a href='https://calendly.com/bombsheltermusic' className='cta' target='_blank' rel='noreferrer'>Book a Session</a>
              </p>
            </div>
            <div className='container_pic fade-in-intersection'>
              <img alt='singer in front of mic in vocal booth' src={singerPic} />
            </div>
          </div>
        </section>
      </main>
      <Footer/>
    </div>
  );
}

function App() {
  return <Home/>;
}

export default App;
